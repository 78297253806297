<template>
  <div id="reset-div">
    <div v-if="error" class="err" :class="{ active: error }">
      {{ $t('alerts.login.errorCode') }}
    </div>
    <div v-if="message" class="info" :class="{ active: true }">
      {{ message }}
    </div>
    <p>
      {{ $t('loginPage.pages.recovery.disclaimer') }}
    </p>
    <v-row align="start">
      <v-col class="pa-2" v-for="index in inputs" :key="index">
        <v-text-field
          v-model="pin.digits[index]"
          :ref="'input'"
          type="number"
          @input="onInput(index)"
          class="ma-0"
          maxLength="1"
          outlined
        ></v-text-field>
      </v-col>
    </v-row>

    <v-btn
      depressed
      block
      color="primary"
      @click="verifyCode"
      :disabled="sending"
      :loading="sending"
    >
      {{ $t('labels.send') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  data: () => ({
    inputs: 6,
    error: false,
    message: false,
    pin: {
      digits: [],
    },
  }),
  methods: {
    onInput(index) {
      if (this.pin.digits.length <= this.inputs) {
        this.$refs.input[index].focus();
      } else {
        this.$refs.input[index - 1].blur();
      }
    },
    async verifyCode() {
      let verifyCodeGtmData = {};
      this.$gtm.trackEvent({
        category: 'verify',
        event: 'click',
        action: 'verify-click',
        label: 'btn-verifiy-code',
      });

      let resetToken = this.pin.digits.join('');
      const result = await this.$store.dispatch('user/verify_code', {
        resetToken,
      });

      if (result) {
        verifyCodeGtmData = {
          category: 'verify',
          event: 'response',
          action: 'verify-response',
          label: 'verify-response-success',
          value: 1,
        };
        this.$router.push({
          name: 'login-reset',
        });
      } else {
        verifyCodeGtmData = {
          category: 'verify',
          event: 'response',
          action: 'verify-response',
          label: 'verify-response-fail',
          value: 0,
        };
        this.error = true;
      }

      this.$gtm.trackEvent(verifyCodeGtmData);
    },
  },
  computed: {
    sending() {
      const sending = this.$store.getters['auth/getSending'];
      return sending;
    },
  },
};
</script>

<style lang="scss">
#reset-div {
  width: 300px;
  margin: 60px auto 0 auto;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  .v-input {
    align-items: flex-start;
    display: flex;
    flex: 1 1 auto;
    font-size: 26px !important;
    letter-spacing: normal;
    max-width: 100%;
    text-align: center;
  }

  .col {
    padding: 8px;
  }
}
</style>
