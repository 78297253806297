<template>
  <f-wrapper page="verify-code">
    <f-login-container>
      <h1>{{ $t('loginPage.pages.verifyCode.title') }}</h1>
    </f-login-container>

    <f-card>
      <f-verify-code-form />
    </f-card>
  </f-wrapper>
</template>

<script>
import FWrapper from '../../components/views/login/Wrapper.vue';
import FCard from '../../components/shared/Card.vue';
import FLoginContainer from '../../components/views/login/LoginContainer.vue';
import FVerifyCodeForm from '../../components/views/login/VerifyCodeForm.vue';

export default {
  components: {
    'f-wrapper': FWrapper,
    FCard,
    FLoginContainer,
    FVerifyCodeForm,
  },
  mounted() {
    let loggedUser = localStorage.getItem('fluke-lms-user');
    if (loggedUser) {
      this.$router.push({
        name: 'course-list',
      });
    }
  },
};
</script>
